import React from "react"
import Layout from "../../components/layout"
import dnb from '../../assets/dnb-thumb.jpg'
import main from '../../assets/casestudies/dnb-main.png'
import charts from '../../assets/casestudies/dnb-charts.png'
import interaction from '../../assets/casestudies/dnb-interaction.png'
import flows from '../../assets/casestudies/dnb-flows.jpg'
import customer from '../../assets/casestudies/dnb-customers.png'
import account from '../../assets/casestudies/dnb-account.png'
import CenteredContent from '../../components/centeredContent'
import FullWidthContent from '../../components/fullWidthContent'

const DNB = () => (
	<Layout headerType="dnb" headerText='Dun & Bradstreet'>
		<CenteredContent
			image1={dnb}
			header="Dun & Bradstreet"
			body="From sketches on whiteboards to pull-requests, as a senior user experience designer I  was responsible for using a human-centered design approach to ship the next-generation  of credit risk management software for Dun & Bradstreet."
			rotation="3deg"
		/>
		<FullWidthContent
			bgcolor1={'#ddfbff'}
			image={main}
			header="UI/UX Design"
			body="DNB used a human-centered design approach to building it's products which involved user research, whiteboarding, sketches, design reviews, prototyping and close collaboartion with stakeholders."
			rotation="3deg"
			bottomAngleMargin="-60px"
			bgPosition="top center"
		/>
		<CenteredContent
			image1={account}
			header="High fidelity prototyping"
			body="I worked with technology and products teams  to produce a high-fidelity prototypes and wireframes that used the unique rich data set of DNB."
			rotation="-3deg"
		/>
		<FullWidthContent
			bgcolor1={'#ddfbff'}
			image={charts}
			header="Charts & data visualizations"
			body="DNB has been around for over a 100 years and with that comes a rich set of historical data. Transforming and visualizing that data for a user to consumer can be challenging. I worked with product and engineering to use cutting-edge visualization tools to make sure our users could get access to the data they needed to inform their decisions. "
			rotation="-3deg"
			bottomAngleMargin="-60px"
			bgPosition="top center"
		/>
		<CenteredContent
			type="web"
			image1={interaction}
			header="Interaction design & coding"
			body="Part of my job at DNB involved being the technical liason between the desing and engineering teams. I provided working code as a guide for interactions and submitted pull-requests to the front-end team to make sure our designs were pixel perfect in the final product."
			rotation="3deg"
		/>
		<FullWidthContent
			bgcolor1={'#ddfbff'}
			image={flows}
			header="User Flows"
			body="I helped created for user flows for the log in screens of the DNB Credit App and what was challenging about this task was that the application had a unique sign up process, having regular users, account admins and a sub account creation process. Defining the user flows, creating wireframes and diagrams helped the product and development teams understand how this process would work."
			rotation="3deg"
			bottomAngleMargin="-60px"
			bgPosition="top center"
		/>
		<CenteredContent
			image1={customer}
			header="Admin interface design"
			body="I worked with the customer support team to craft the tools and UI they needed to best help our customers by providing an intuitive and easy to use design with the features they requested."
			rotation="-3deg"
		/>
	</Layout>
)

export default DNB
